import he from 'he'
import fecha from 'fecha'

import { getLocaleWeekShort } from './calendar'
import { links, dateFormat } from './constants'

// capitalizes first letter from string
export const capitalize = text => text.charAt(0).toUpperCase() + text.slice(1)

// converts new line to break line
export const nl2br = text => (text ? text.replace(/(\n)/g, '<br />') : '')

// converts break line to new line
export const br2nl = text => (text ? text.replace(/<br\s*[/]?>/gi, '\n') : '')

// extract one property from node as array of items
export const extractNode = (items, property) => {
  return items.map(({ node }) => node[`${property}`])
}

export const validateEmail = email => {
  var re = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return re.test(email)
}

// flatten list of arrays into one
export const arrayCombine = arrays => {
  return arrays.reduce((prev, curr) => {
    return [...prev, ...curr]
  }, [])
}

// returns unique elements as an array
export const arrayUnique = elements => {
  return Array.from(new Set(elements))
}

// flatten and returns unique elements array
export const arrayCombineUnique = arrays => {
  return arrayUnique(arrayCombine(arrays))
}

export const getSharedCategories = categories => {
  return categories
    .filter(({ node: { type } }) => type === 'Themen')
    .map(({ node: { title } }) => title)
    .filter((val, idx, arr) => arr.indexOf(val) === idx)
}

export const getItemsCategories = (categories, service) => {
  return categories
    .filter(({ node }) => (node.type === 'Arten' || node.type === 'Kategorien') && node.service === service)
    .map(({ node: { title } }) => title)
}

// unescape special characters from html elements inner text. Use only after component is mounted
export const unescape = text => {
  if (!text) {
    return ''
  }

  return he.decode(text)
}

export const stripHtml = text => {
  if (!text) {
    return ''
  }

  return text.replace(/<br\s*[/]?>/gi, ' ').replace(/<(?:.|\n)*?>/gm, '')
}

export const scrollToTop = () => {
  document !== undefined &&
    document.addEventListener('scroll', () => {
      const windowheight = document.getElementById('___gatsby').clientHeight;
      if (window.scrollY > ((windowheight / 2) - window.innerHeight)) {
        document.body.classList.add('ontop')
      } else {
        document.body.classList.remove('ontop')
      }
    })

  document !== undefined &&
    document.getElementById('ontop') &&
    document.getElementById('ontop').addEventListener('click', () => {
      typeof window !== 'undefined' && window.scrollTo(0, 0)
    })
}

export const getSearchParams = search => {
  const params = {}

  const searchParams = search.slice(1).split('&')

  searchParams.forEach(param => {
    let [name, value] = param.split('=')

    params[name] = value
  })

  return params
}

export const localizeLink = (originalLink, lang) => {
  let link = originalLink

  Object.keys(links).forEach(pageName => {
    if (link.includes(`/${pageName}`)) {
      const reg = new RegExp(`(/${pageName})(?=[/?]|$)`, 'g')
      link = link.replace(reg, `/${links[pageName][lang]}`)
    }
  })

  return link
}

export const relocalizeLink = (lang, pageContext) => {
  const currentLang = lang
  const oldLang = currentLang === 'de' ? 'fr' : 'de'

  const segments = pageContext && pageContext.url ? pageContext.url.split('/') : []
  // console.log(pageContext)

  let oldLink
  segments[1] = lang

  if (segments[2] === 'blog') {
    // if we're on blog post page, user will be redirected to '/{lang}/blog', 
    // as we don't have any info in pageContext.redirect object from WP
    oldLink = `${segments[0]}/${segments[1]}/${segments[2]}`
  } else {
    oldLink = segments.join('/')
  }

  if (pageContext && pageContext.redirect) {
    if (pageContext.slug && pageContext.redirect.slug) {
      oldLink = oldLink.replace(pageContext.slug, pageContext.redirect.slug)
    }
    if (pageContext.countrySlug && pageContext.redirect.countrySlug) {
      oldLink = oldLink.replace(pageContext.countrySlug, pageContext.redirect.countrySlug)
    }
    if (pageContext.regionSlug && pageContext.redirect.regionSlug) {
      oldLink = oldLink.replace(pageContext.regionSlug, pageContext.redirect.regionSlug)
    }
    if (pageContext.locationSlug && pageContext.redirect.locationSlug) {
      oldLink = oldLink.replace(pageContext.locationSlug, pageContext.redirect.locationSlug)
    }
  }

  let link = oldLink

  Object.keys(links).forEach(pageName => {
    if (link.includes(`/${links[pageName][oldLang]}`)) {
      link = link.replace(`/${links[pageName][oldLang]}`, `/${links[pageName][currentLang]}`)
    }
  })

  return link
}

export const parseQueryFilters = (path, search) => {
  // console.log('parseQueryFilters', path, search)
  // console.log(path.split('/'))

  // if (!search) {
  //   return {}
  // }

  const filtersParams = search.slice(1).split('&')
  let filters = {}

  filtersParams.forEach(filter => {
    let [name, value] = filter.split('=')
    if (name === 'hotels' || name === 'tours' || name === 'trips') {
      filters[`${name}Categories`] = decodeURI(value).split(',')
    } else if (name === 'categories') {
      filters['sharedCategories'] = decodeURI(value).split(',')
    } else if (name === 'durationMin') {
      filters.duration = { ...filters.duration, min: decodeURI(value) }
    } else if (name === 'durationMax') {
      filters.duration = { ...filters.duration, max: decodeURI(value) }
    } else if (name === 'stars') {
      filters.stars = Number(value)
    }
    // else if (name === 'region' || name === 'location') {
    //   filters[name] = decodeURI(value)
    // } else if (name === 'service') {
    //   filters[name] = value
    // }
  })

  // '/fr/destinations/bhoutan/tous-les-services/regions/paro/lieux/paro'
  const pathParts = path.split('/')
  const serviceGroup = pathParts[4]
  // const country = pathParts[3]
  // const region = pathParts[6]
  // const location = pathParts[8]

  if (serviceGroup === 'rundreisen' || serviceGroup === 'circuits') {
    filters.service = 'tours'
  } else if (serviceGroup === 'hotels' || serviceGroup === 'hotels') {
    filters.service = 'hotels'
  } else if (serviceGroup === 'ausfluge' || serviceGroup === 'excursions') {
    filters.service = 'trips'
  }

  return filters
}

export const encodeQueryFilters = filters => {
  let query = '?'

  Object.entries(filters).forEach(filter => {
    // if (filter[0] === 'region' || filter[0] === 'location' || filter[0] === 'stars' || filter[0] === 'service') {
    if (filter[0] === 'stars') {
      query += filter[1] ? `${filter[0]}=${filter[1]}&` : ''
    } else if (filter[0] === 'duration') {

      query += filter[1].min && filter[1].min !== 1 ? `durationMin=${filter[1].min}&` : ''
      query += filter[1].max && filter[1].max !== 18 ? `durationMax=${filter[1].max}&` : ''
    } else if (filter[0] === 'hotelsCategories' || filter[0] === 'toursCategories' || filter[0] === 'tripsCategories') {
      query += filter[1].length ? `${filter[0].replace('Categories', '')}=${filter[1].join(',')}&` : ''
    } else if (filter[0] === 'sharedCategories') {
      query += filter[1].length ? `categories=${filter[1].join(',')}&` : ''
    }
  })

  return query.slice(0, -1)
}

export const convertDate = (date, options = {}) => {
  fecha.i18n = {
    dayNamesShort: getLocaleWeekShort()
  }

  const { inFormat = 'YYYY-MM-DD', outFormat = dateFormat } = options

  if (!date) {
    return ''
  }

  const splitted = date.split('-')

  if (!splitted[0] || !splitted[1] || !splitted[2]) {
    return ''
  }

  return fecha.format(fecha.parse(date, inFormat), outFormat)
}

const extractDate = (date) => {
  if (!date || typeof date !== 'string') {
    return date
  }
  const splitted = date.split(',')
  const trimmedDate = splitted[1].trim()
  return fecha.parse(trimmedDate, 'DD.MM.YYYY')
}

//Date sort
export const dateSort = (a, b) => {
  const beginDateA = extractDate(a.beginDate)
  const beginDateB = extractDate(b.beginDate)
  const endDateA = a.endDate ? extractDate(a.endDate) : ""
  const endDateB = b.endDate ? extractDate(b.endDate) : ""

  if (beginDateA > beginDateB) return 1
  if (beginDateA < beginDateB) return -1
  if (beginDateA === beginDateB && endDateA && !b.EndDate) return 1
  if (beginDateA === beginDateB && endDateA && endDateB && endDateA > endDateB) return -1
  if (beginDateA === beginDateB && endDateA && endDateB && endDateA < endDateB) return 1
  return 0
}

//Position string sort
export const positionSort = (a, b) => {
  if (!a.position || !b.position || a.position === b.position) return 0;
  if (Number(a.position) > Number(b.position)) return 1
  if (Number(a.position) < Number(b.position)) return -1
}