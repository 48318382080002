export const loadState = () => {
  try {
    const serializedState = window.localStorage.getItem('letsgo')
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (errors) {
    return undefined
  }
}

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state)
    window.localStorage.setItem('letsgo', serializedState)
  } catch (errors) {
    // catch any errors
  }
}
